<template>
  <v-card 
    :flat="!smUp"
    rounded
    :style="smUp ? 'padding: 15px; margin-left: -5%':''">
    <v-data-table
      hide-default-footer
      :headers="headers"
      :items="items"
      :items-per-page="20"
    >
    <!-- Start table view -->
    <template 
      v-slot:item="props">
      <tr
        v-if="smUp"
        class="application_table_row">
        <td
          class="text-left">
          <p 
            v-html="props.item.sAlarmActivated"
            class="text_content"
          />
        </td>
        <td
          class="text-left">
          <p 
            v-html="props.item.sEstimatedRecoveryTime"
            class="text_content"
          />
        </td>
        <td
          class="text-left">
          <p 
            v-html="status_to_text(props.item.iStatus)"
            class="text_content"
          />
        </td>
        <td
          class="text-left">
          <p 
            v-html="location_to_text(props.item.iLocation)"
            class="text_content"
          />
        </td>
        <td
          class="text-left">
          <p 
            v-html="type_to_text(props.item.iType)"
            class="text_content"
          />
        </td>
        <td
          class="text-left">
          <p 
            v-html="props.item.formatedCreatedAt"
            class="text_content"
          />
        </td>
        <td
          class="text-left">
          <v-btn
            dark
            color="grey"
            text
            fab
            small
            @click="setup_add_file(props.item)">
            <v-icon>
              {{add_file_icon}}
            </v-icon>
          </v-btn>
          <v-btn
            dark
            color="blue"
            text
            fab
            small
            @click="add_notif(props.item)">
            <v-icon>
              {{add_notification_icon}}
            </v-icon>
          </v-btn>
          <v-btn
            fab
            small
            icon
            text
             @click="edit_operator(props.item)">
            <v-icon
              color="black">
              {{edit_icon}}
            </v-icon>
          </v-btn>
          <v-btn
            fab
            small
            icon
            text
            @click="delete_operator(props.item)">
            <v-icon
              color="red">
              {{delete_icon}}
            </v-icon>
          </v-btn>
          <v-btn
            fab
            small
            icon
            text
            @click="preview_outage_notifications(props.item)">
            <v-icon
              color="green">
              {{notification_history_icon}}
            </v-icon>
          </v-btn>
        </td>
      </tr>
      <!-- End table view -->
      <!-- Start mobile table view -->
      <v-card
        v-else
        style="padding: 10%; margin-bottom: 20px; margin-top: 20px"
        hover
        shaped
        >
        <v-container>
          <v-layout
            row
            justify-center
            wrap>
            <v-flex
              class="attribute_flex"
              xs12
              sm6>
              <v-layout
                row
                justify-center>
                <v-layout
                  style="max-width: 60%"
                  row>
                  <p
                    class="attribute_name"
                    v-text="'Alarm activated: '"
                  />
                  <v-spacer></v-spacer>
                  <p
                    class="attribute_value"
                    v-text="props.item.sAlarmActivated"
                  />
                </v-layout>
              </v-layout>
            </v-flex>
            <v-layout
              column>
              <hr
                class="divider_table_mobile">
            </v-layout>
            <v-flex
              class="attribute_flex"
              xs12
              sm6>
              <v-layout
                row
                justify-center>
                <v-layout
                  style="max-width: 60%"
                  row>
                  <p
                    class="attribute_name"
                    v-text="'Recovery Time: '"
                  />
                  <v-spacer></v-spacer>
                  <p
                    class="attribute_value"
                    v-text="props.item.sEstimatedRecoveryTime"
                  />
                </v-layout>
              </v-layout>
            </v-flex>
            <v-layout
              column>
              <hr
                class="divider_table_mobile">
            </v-layout>
            <v-flex
              class="attribute_flex"
              xs12
              sm6>
              <v-layout
                row
                justify-center>
                <v-layout
                  style="max-width: 60%"
                  row>
                  <p
                    class="attribute_name"
                    v-text="'Status: '"
                  />
                  <v-spacer></v-spacer>
                  <p
                    class="attribute_value"
                    v-text="status_to_text(props.item.iStatus)"
                  />
                </v-layout>
              </v-layout>
            </v-flex>
            <v-layout
              column>
              <hr
                class="divider_table_mobile">
            </v-layout>
            <v-flex
              class="attribute_flex"
              xs12
              sm6>
              <v-layout
                row
                justify-center>
                <v-layout
                  style="max-width: 60%"
                  row>
                  <p
                    class="attribute_name"
                    v-text="'Location: '"
                  />
                  <v-spacer></v-spacer>
                  <p
                    class="attribute_value"
                    v-text="location_to_text(props.item.iLocation)"
                  />
                </v-layout>
              </v-layout>
            </v-flex>
            <v-layout
              column>
              <hr
                class="divider_table_mobile">
            </v-layout>
            <v-flex
              class="attribute_flex"
              xs12
              sm6>
              <v-layout
                row
                justify-center>
                <v-layout
                  style="max-width: 60%"
                  row>
                  <p
                    class="attribute_name"
                    v-text="'Location: '"
                  />
                  <v-spacer></v-spacer>
                  <p
                    class="attribute_value"
                    v-text="type_to_text(props.item.iType)"
                  />
                </v-layout>
              </v-layout>
            </v-flex>
            <v-layout
              column>
              <hr
                class="divider_table_mobile">
            </v-layout>
            <v-flex
              xs12
              sm12>
              <v-layout
                row
                justify-center>
                <v-layout
                  style="max-width: 90%; margin: 10px"
                  row
                  wrap
                  justify-center>
                  <v-flex
                    xs4>
                    <v-layout
                      row
                      justify-center>
                      <v-btn
                        dark
                        color="grey"
                        fab
                        small
                        @click="setup_add_file(props.item)">
                        <v-icon>
                          {{add_file_icon}}
                        </v-icon>
                      </v-btn>
                    </v-layout>
                  </v-flex>
                  <v-flex
                    xs4>
                    <v-layout
                      row
                      justify-center>
                      <v-btn
                        dark
                        color="blue"
                        fab
                        small
                        @click="add_notif(props.item)">
                        <v-icon>
                          {{add_notification_icon}}
                        </v-icon>
                      </v-btn>
                    </v-layout>
                  </v-flex>
                  <v-flex
                    xs4>
                    <v-layout
                      row
                      justify-center>
                      <v-btn
                        dark
                        color="black"
                        fab
                        small
                        @click="edit_operator(props.item)">
                        <v-icon>
                          {{edit_icon}}
                        </v-icon>
                      </v-btn>
                    </v-layout>
                  </v-flex>
                  <v-flex
                    xs6>
                    <v-layout
                      row
                      justify-center>
                      <v-btn
                        dark
                        color="red"
                        fab
                        small
                        @click="delete_operator(props.item)">
                        <v-icon>
                          {{delete_icon}}
                        </v-icon>
                      </v-btn>
                    </v-layout>
                  </v-flex>
                  <v-flex
                    xs6>
                    <v-layout
                      row
                      justify-center>
                      <v-btn
                        dark
                        color="green"
                        fab
                        small
                        @click="preview_outage_notifications(props.item)">
                        <v-icon>
                          {{notification_history_icon}}
                        </v-icon>
                      </v-btn>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card>
      <!-- End mobile table view -->
    </template>
    </v-data-table>    
    <outage-edit
      :bActive="b_edit_outage"
      :outages_to_edit="outages_to_edit"
      @close="b_edit_outage = false"
      @update="update()"
    />
    <outage-editauto
      :bActive="bEditOutageAuto"
      @update="update()"
      @close="bEditOutageAuto = false"
    />
    <outage-delete
      :bActive="b_delete_outage"
      :outage_id="outage_to_delete"
      @close="b_delete_outage = false"
      @update="update()"
    />
    <outage-notification
      :bActive="add_notification"
      :outage_id="outage_id"
      @close="add_notification = false"
      @update="reload_page()"
    />
    <outage-file
      :outageDetails="outageDetails"
      :bActive="add_file"
      :outage_id="outage_id"
      @close="add_file = false"
      @update="update()"
    />
    <outage-history
      :bActive="preview_notifications"
      :outage_id="outage_id"
      @close="preview_notifications = false"
      @update="update()"
    />
  </v-card>
</template>
<script>
  export default {
    mounted () {
      if (localStorage.getItem('OutageEdit') !== null) {
        this.bEditOutageAuto = true
      }
    },
    data: () => ({
      bEditOutageAuto: false,
      notification_history_icon: 'mdi-table-large',
      preview_notifications: false,
      add_notification: false,
      b_edit_outage: false,
      b_delete_outage: false,
      add_file: false,
      add_notification_icon: 'mdi-bell-ring-outline',
      outages_to_edit: {},
      outage_to_delete: null,
      delete_icon: 'mdi-delete',
      edit_icon: 'mdi-pencil',
      add_file_icon: 'mdi-file',
      headers: [
        { text: 'Alarm Activated', value: 'sAlarmActivated' },
        { text: 'Recovery Time', value: 'sRecoveryTime' },
        { text: 'Status', value: 'sStatus' },
        { text: 'Location', value: 'sLocation' },
        { text: 'Type', value: 'iType' },
        { text: 'Date', value: 'sDate' },
        { text: 'Actions'}
      ],
      outage_id: '',
      outageDetails: null
    }),
    methods: {
      reload_page () {
        if (localStorage.getItem('timer-status') == 'true') {
          localStorage.getItem('timer-status', 'false')
          localStorage.setItem('date-start', null)
          window.location.reload()
        } else {
          this.$emit('update')
        }
      },
      type_to_text (iType) {
        switch (iType) {
          case 0: 
            return 'Unspecified'
          case 1: 
            return 'Partial'
          case 2: 
            return 'Total'
        }
      },
      status_to_text (iType) {
        switch (iType) {
          case 0: 
            return 'Unspecified'
          case 1: 
            return 'Supplying to Kia & Mobis'
          case 2: 
            return 'Only Kia'
          case 3: 
            return 'Only Mobis'
          case 4: 
            return 'Limited or partial supply to Kia and Mobis'
          case 5: 
            return 'Limited or partial supply to Kia'
          case 6: 
            return 'Limited or partial supply to Mobis'
        }
      },
      location_to_text (iType) {
        switch (iType) {
          case 0: 
            return 'Unspecified'
          case 1: 
            return 'Inside the fence'
          case 2: 
            return 'Outside the fence'
          case 3: 
            return 'OTransmission Line'
        }
      },
      preview_outage_notifications (outage) {
        this.outage_id = outage._id
        this.preview_notifications = true
      },
      setup_add_file (outage) {
        this.outageDetails = outage
        this.outage_id = outage._id
        this.add_file = true
      },
      update () {
        this.$emit('update')
      },
      add_notif (outage) {
        this.outage_id = outage._id
        this.add_notification = true
      },
      edit_operator (client) {
        this.outages_to_edit = client
        this.b_edit_outage = true
      },
      delete_operator (client) {
        this.outage_to_delete = client._id
        this.b_delete_outage = true
      }
    },
    components: {
      OutageEdit: () => import('@/components/sections/Admin/Outages/OutagesEdit'),
      OutageEditauto: () => import('@/components/sections/Admin/Outages/OutagesEditAutomatic'),
      OutageDelete: () => import('@/components/sections/Admin/Outages/OutagesDelete'),
      OutageNotification: () => import('@/components/sections/Admin/Outages/OutagesNotification'),
      OutageFile: () => import('@/components/sections/Admin/Outages/OutagesFile'),
      OutageHistory: () => import('@/components/sections/Admin/Outages/OutageHistory')
    },
    props: {
      items: Array
    },
    computed: {
      smUp () {
        return this.$vuetify.breakpoint.smAndUp
      }
    }
  }
</script>
<style scoped>
  .attribute_flex {
    margin-top: 18px;
  }
  hr { 
    height: .5px;
    color: rgba(140, 140, 140, 0.112);
    background-color: rgba(114, 114, 114, 0.112);
    border-color: transparent;

  }
  .attribute_name {
    font-weight: bold;
    color: #000000;
    font-size: 12px;
    font-family: 'Open sans', sans-serif
  }
  .attribute_value {
    color: #000000;
    font-size: 12px;
    font-family: 'Open sans', sans-serif
  }
  .application_table_row {
    cursor: pointer;
  }
  .text_content {
    margin-top: 15px;
    color: #000000;
    font-size: 12px;
    font-family: 'Open sans', sans-serif
  }
</style>